import { useEffect, useState } from 'react';
import { createSortable } from '../../utils/sorting';
import NoContent from '../common/NoContent';
import SongPart from '../SongPart';

import './SongParts.scss';

function SongParts({ editPartId, parts, onPartMove, onPartDelete, onPartDuplicate, onPartEdit, onPartSort }) {
  const [songPartsRef, setSongPartsRef] = useState(null);

  useEffect(() => {
    if (songPartsRef) createSortable(songPartsRef, onPartSort);
  }, [songPartsRef, onPartSort]);

  return (
    <>
      {parts.length !== 0 ? (
        <ul className="song-parts" ref={(ref) => setSongPartsRef(ref)}>
          {parts.map((part, partIndex) => (
            <SongPart
              part={part}
              parts={parts}
              partIndex={partIndex}
              onPartMove={onPartMove}
              onPartDelete={onPartDelete}
              onPartDuplicate={onPartDuplicate}
              onPartEdit={onPartEdit}
              editPartId={editPartId}
              key={part.id}
            />
          ))}
        </ul>
      ) : (
        <NoContent message="No Parts" />
      )}
    </>
  );
}

export default SongParts;
