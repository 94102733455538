import Icon from './Icon';

import './Title.scss';

const Title = ({ name, icon }) => {
  return (
    <h5 className="title">
      <Icon icon={icon} /> <span> {name}</span>
    </h5>
  );
};

export default Title;
