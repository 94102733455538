import { useContext } from 'react';
import ServiceContext from '../context/serviceContext';
import Icon from '../common/Icon';
import { ReactComponent as PlusCircle } from '../../icons/plus-circle.svg';
import { ReactComponent as CheckCircleIcon } from '../../icons/check-circle-solid.svg';

import './AddToService.scss';

const AddToService = ({ onClick, song }) => {
  const serviceContext = useContext(ServiceContext);

  const service = serviceContext.currentService;
  const songFound = service.find((s) => s._id === song._id);
  const icon = songFound ? <CheckCircleIcon /> : <PlusCircle />;
  const type = songFound ? 'solid' : 'empty';

  return (
    <div className="AddToService d-block text-right clickable">
      <Icon icon={icon} onClick={onClick} className={type} />
    </div>
  );
};

export default AddToService;
