import { Component } from 'react';
import ServiceContext from '../../components/context/serviceContext';
import { toast } from 'react-toastify';
import _ from 'lodash/collection';
import serviceService from '../../services/serviceService';
import { sortItem } from '../../utils/sorting';
import songService from '../../services/songService';
import { getCategories } from '../../services/fakeCategoryService';
import Services from '../../components/Services';
import SongList from '../../components/SongList';
import Pagination from '../../components/common/Pagination';
import paginate from '../../utils/paginate';
import { scrollTop, getRemainingDays } from '../../utils/utilities';
import SongsMenu from '../../components/SongsMenu';
import SearchBox from '../../components/common/SearchBox';
import TagGroup from '../../components/common/TagGroup';
import ServiceForm from '../../components/ServiceForm';
import userService from '../../services/userService';
import auth from '../../services/authService';
import Title from '../../components/common/Title';
import { setDocTitle } from '../../utils/utilities';
import Modal from '../../components/common/Modal';
import sendDiscordMessage from '../../utils/discord';
import { ReactComponent as SongsIcon } from '../../icons/playlist.svg';
import { ReactComponent as MusicIcon } from '../../icons/music-note-plus-solid.svg';
import { ReactComponent as PlaylistIcon } from '../../icons/playlist-play.svg';
import './Main.css';

class Songs extends Component {
  state = {
    songs: [],
    categories: [],
    pageSize: 50,
    currentPage: 1,
    categoryId: 'all',
    selectedCategory: null,
    searchQuery: '',
    sortColumn: { path: 'title', order: 'none' },
    createService: false,
    currentService: [],
    currentServiceTitle: '',
    currentServiceDate: '',
    editServiceId: null,
    services: [],
    loadingSongs: false,
    loadingServices: false,
    savingService: false,
  };

  async componentDidMount() {
    setDocTitle('Songs');
    this.setState({ loadingSongs: true, loadingServices: true });
    await this.populateSongs();
    await this.populateCategories();
    await this.populateServices();
  }

  populateSongs = async () => {
    try {
      const user = auth.getCurrentUser();
      let songs = await songService.getSongs(user.teamId);
      const { data: favoriteSongs } = await userService.getFavoriteSongs();

      // set favorites
      if (favoriteSongs.length > 0) {
        for (let i = 0; i < favoriteSongs.length; i++) {
          let favSong = songs.find((s) => s._id === favoriteSongs[i]);
          // if the song was deleted by the admin, remove it from favorites
          if (!favSong) {
            try {
              await userService.removeFavoriteSong(favoriteSongs[i]);
            } catch (ex) {}
          } else {
            favSong.favorite = true;
            const index = songs.indexOf(favSong);
            songs.splice(index, 1, favSong);
          }
        }
      }

      this.setState({ songs, loadingSongs: false });
    } catch (e) {
      this.setState({ loadingSongs: false });
    }
  };

  populateCategories = async () => {
    const categories = [{ _id: 'all', name: 'All Songs' }, { _id: 'favorites', name: 'Favorites' }, ...getCategories()];
    this.setState({ categories });
  };

  populateServices = async () => {
    try {
      let { data: services } = await serviceService.getServices();

      services = this.sortPastServices(services);

      this.setState({ services, loadingServices: false });
    } catch (e) {
      this.setState({ loadingServices: false });
    }
  };

  sortPastServices = (services) => {
    const pastServices = [];
    const otherServices = [];

    for (const service of services) {
      if (getRemainingDays(service.date) < 0) {
        pastServices.push(service);
      } else {
        otherServices.push(service);
      }
    }

    pastServices.reverse();

    return [...otherServices, ...pastServices];
  };

  handleDelete = async (song) => {
    this.setState({ modalDeleteSong: song });
  };

  deleteSong = async () => {
    // this is set by handleDelete
    const song = this.state.modalDeleteSong;

    const originalSongs = this.state.songs;

    const songs = originalSongs.filter((s) => s._id !== song._id);
    this.setState({ songs });

    try {
      await songService.deleteSong(song._id);
      // remove song from favorites
      const favSong = originalSongs.find((s) => s._id === song._id);
      if (favSong.favorite) await userService.removeFavoriteSong(song._id);
      // remove song from services
      await this.checkForSongInService(song._id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404) toast.error('This song has already been deleted.');
      this.setState({ songs: originalSongs });
    }
  };

  checkForSongInService = async (songId) => {
    const { services } = this.state;
    // no services found
    if (services.length < 1) return;

    // loop through all the services
    for (let i = 0; i < services.length; i++) {
      const service = services[i];
      // loop through the songsIds in current service
      for (let j = 0; j < service.songsIds.length; j++) {
        // if the song id is found in current service delete it
        if (service.songsIds[j] === songId) {
          const { data: updatedService } = await serviceService.deleteSongInService(service._id, songId);

          this.updateServiceInState(updatedService);

          break;
        }
      }
    }
  };

  updateServiceInState = (updatedService) => {
    const services = [...this.state.services];
    const service = services.find((s) => s._id === updatedService._id);

    const index = services.indexOf(service);
    services.splice(index, 1, updatedService);

    this.setState({ services: services });
  };

  handleFavorite = async (song) => {
    const songs = this.state.songs;
    const index = songs.indexOf(song);
    songs[index] = { ...song };
    // toggle the favorite property
    songs[index].favorite = !songs[index].favorite;
    this.setState({ songs });

    if (songs[index].favorite) {
      await userService.saveFavoriteSong(songs[index]._id);
    } else {
      await userService.removeFavoriteSong(songs[index]._id);
    }
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
    scrollTop('search-box-scroll-id');
  };

  handleCategorySelect = (category) => {
    this.setState({
      categoryId: category._id,
      searchQuery: '',
      currentPage: 1,
    });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      categoryId: null,
      currentPage: 1,
    });
  };

  handleClearInput = () => {
    this.setState({ searchQuery: '', categoryId: 'all' });
  };

  getPagedData = () => {
    const { pageSize, currentPage, songs: allSongs, categoryId, sortColumn, searchQuery } = this.state;
    // filtering
    let filtered = allSongs;
    if (searchQuery) {
      filtered = allSongs.filter((song) => song.title.toLowerCase().includes(searchQuery.toLowerCase()));
    }
    if (categoryId && categoryId !== 'all' && categoryId !== 'favorites')
      filtered = allSongs.filter((song) => song.category._id === categoryId);

    // filtering favorites
    if (categoryId && categoryId === 'favorites') filtered = allSongs.filter((song) => song.favorite === true);

    // sorting
    let sorted = null;
    if (sortColumn.order !== 'none') {
      sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    } else {
      sorted = _.orderBy(filtered, 'createdAt', 'desc');
    }

    // paginating
    const songs = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: songs };
  };

  toggleCreateService = () => {
    this.setState({ createService: !this.state.createService });
  };

  handleNewSong = () => {
    this.props.history.push('/songs/new');
  };

  handleEditSong = (song) => {
    this.props.history.push(`/songs/${song._id}`);
  };

  renderServiceCount = () => {
    const { currentService } = this.state;
    return currentService.length > 0 ? currentService.length : '';
  };

  handleAddToService = (song) => {
    const currentService = [...this.state.currentService];
    const songFound = currentService.find((s) => s._id === song._id);
    if (!songFound) {
      currentService.push(song);
    } else {
      // delete song if it has already been added.
      const index = currentService.indexOf(songFound);
      currentService.splice(index, 1);
    }
    this.setState({ currentService });
  };

  handleDeleteFromService = (song) => {
    let { currentService } = this.state;
    currentService = currentService.filter((s) => s._id !== song._id);
    this.setState({ currentService });
  };

  handleSortInService = (song, direction) => {
    const { currentService } = this.state;
    const sorted = sortItem(song, currentService, direction);
    this.setState({ currentService: sorted });
  };

  handleViewService = (service) => {
    this.props.history.push(`/view/service/${service._id}`);
  };

  handleDeleteService = (service) => {
    this.setState({ modalDeleteService: service });
  };

  deleteService = async () => {
    // this is set by handleDeleteService
    const service = this.state.modalDeleteService;

    const originalServices = this.state.services;
    const updatedServices = originalServices.filter((s) => s._id !== service._id);

    try {
      this.setState({ services: updatedServices });
      await serviceService.deleteService(service._id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        toast.error('This service was already deleted.');
      }
      this.setState({ services: originalServices });
    }
  };

  handleEditService = (service) => {
    let { currentService, editServiceId } = this.state;
    currentService = this.getCurrenServiceSongs(service.songsIds);
    editServiceId = service._id;

    this.setState({
      currentService,
      editServiceId,
      createService: true,
      currentServiceTitle: service.title,
      currentServiceDate: service.date,
    });
  };

  handleCreateService = async (title, date) => {
    const { editServiceId } = this.state;
    let services = [...this.state.services];

    // A new service
    if (!editServiceId) {
      try {
        this.setState({ savingService: true });
        // Save Service
        const { data: newService } = await serviceService.saveService({
          title,
          date,
          songsIds: this.getCurrentServiceIds(),
        });

        // Add Youtube links
        const newServiceWithLinks = this.addYoutubeLinks(newService);
        services.push(newServiceWithLinks);

        // Sort services
        services = _.orderBy(services, 'date');

        // Push past due services to the bottom
        services = this.sortPastServices(services);
      } catch (ex) {
        this.setState({ savingService: false, createService: true });
        return;
      }
    } else {
      // Updating a service
      const service = services.find((s) => s._id === editServiceId);
      const serviceIndex = services.indexOf(service);

      const updatedService = {
        _id: service._id,
        title: title,
        date: date.toISOString(),
        songsIds: this.getCurrentServiceIds(),
        teamId: service.teamId,
      };

      // Add YouTube links
      const updatedServiceWithLinks = this.addYoutubeLinks(updatedService);
      services.splice(serviceIndex, 1, updatedServiceWithLinks);

      // Sort services
      services = _.orderBy(services, 'date');

      // Push past due services to the bottom
      services = this.sortPastServices(services);

      // Save Service
      try {
        this.setState({ savingService: true });

        await serviceService.saveService(updatedService);

        // Send Discord Message
        sendDiscordMessage(this.state, service, updatedService);
      } catch (ex) {
        this.setState({ savingService: false, createService: true });
        return;
      }
    }

    this.setState({
      savingService: false,
      createService: false,
      services,
      currentService: [],
      editServiceId: null,
    });

    scrollTop();
  };

  getCurrentServiceIds = () => {
    const { currentService } = this.state;
    const currentServiceIds = [];
    for (let i = 0; i < currentService.length; i++) {
      currentServiceIds.push(currentService[i]._id);
    }

    return currentServiceIds;
  };

  getCurrenServiceSongs = (songsIds) => {
    const { songs } = this.state;
    let currentServiceSongs = [];

    for (let i = 0; i < songsIds.length; i++) {
      currentServiceSongs.push(songs.find((s) => s._id === songsIds[i]));
    }

    return currentServiceSongs;
  };

  addYoutubeLinks = (service) => {
    const { songs } = this.state;
    const links = [];
    const serviceWithLinks = { ...service };

    for (let songId of serviceWithLinks.songsIds) {
      const { youtube: link } = songs.find((song) => song._id === songId);

      if (link) links.push(link);
    }

    if (links.length > 0) {
      serviceWithLinks.links = links;
      return serviceWithLinks;
    }

    return service;
  };

  handleSongServiceSort = (oldIndex, newIndex) => {
    if (oldIndex === newIndex) return;

    const { currentService } = this.state;

    // move song service
    currentService.splice(newIndex, 0, currentService.splice(oldIndex, 1)[0]);

    this.setState({ currentService });
  };

  render() {
    const { currentService, currentServiceTitle, currentServiceDate, createService, editServiceId } = this.state;

    const user = auth.getCurrentUser();
    const { pageSize, currentPage, categories, loadingSongs, loadingServices, savingService } = this.state;
    const { searchQuery, categoryId } = this.state;
    const { totalCount, data: songs } = this.getPagedData();

    switch (createService) {
      case true:
        return (
          <ServiceForm
            editServiceId={editServiceId}
            currentService={currentService}
            title={currentServiceTitle}
            date={currentServiceDate}
            onDeleteFromService={this.handleDeleteFromService}
            onSortInService={this.handleSortInService}
            onDragSortInService={this.handleSongServiceSort}
            onCreateService={this.handleCreateService}
            onToggleCreateService={this.toggleCreateService}
            onNewSong={this.handleNewSong}
            savingService={savingService}
          />
        );
      default:
        return (
          <>
            {user.isAdmin && (
              <SongsMenu
                serviceCount={this.renderServiceCount}
                onCreateService={this.toggleCreateService}
                onNewSong={this.handleNewSong}
              />
            )}
            <div className="container">
              <Modal
                modalId="deleteSong"
                btnText="Delete Song"
                icon={<MusicIcon />}
                item={this.state.modalDeleteSong}
                onDelete={this.deleteSong}
              />
              <Modal
                modalId="deleteService"
                btnText="Delete Service"
                icon={<PlaylistIcon />}
                item={this.state.modalDeleteService}
                onDelete={this.deleteService}
              />
              <div className="songs">
                <div className="row">
                  <div className="col-lg-4">
                    <Services
                      loading={loadingServices}
                      services={this.state.services}
                      editServiceId={editServiceId}
                      onEditService={this.handleEditService}
                      onDeleteService={this.handleDeleteService}
                      onViewService={this.handleViewService}
                    />
                  </div>

                  <div className="col-lg-8">
                    <Title name="Songs" icon={<SongsIcon />} />
                    {!loadingSongs && (
                      <>
                        <SearchBox
                          value={searchQuery}
                          label="Search songs"
                          onChange={this.handleSearch}
                          onClearInput={this.handleClearInput}
                        />

                        <div className="d-flex align-items-center mb-2">
                          <TagGroup
                            name="categories"
                            tags={categories}
                            selectedTagId={categoryId}
                            onTagClick={this.handleCategorySelect}
                          />
                          <span id="song-count">{totalCount} songs</span>
                        </div>
                      </>
                    )}
                    <ServiceContext.Provider
                      value={{
                        currentService: this.state.currentService,
                      }}
                    >
                      <SongList
                        songs={songs}
                        onFav={this.handleFavorite}
                        onDel={this.handleDelete}
                        onEdit={this.handleEditSong}
                        onAddToService={this.handleAddToService}
                        loading={loadingSongs}
                      />
                    </ServiceContext.Provider>
                    <Pagination
                      itemsCount={totalCount}
                      pageSize={pageSize}
                      currentPage={currentPage}
                      onPageChange={this.handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        );
    }
  }
}

export default Songs;
