import Joi from 'joi-browser';
import Calendar from 'react-calendar';
import NoContent from '../common/NoContent';
import Form from '../common/Form';
import ServiceSongs from '../ServiceSongs';
import Title from '../common/Title';
import TopMenu from '../common/TopMenu';
import Footer from '../common/Footer';
import { ReactComponent as CreateServiceIcon } from '../../icons/playlist-plus.svg';
import { ReactComponent as AddCircleIcon } from '../../icons/plus-circle.svg';
import './ServiceForm.css';

class ServiceForm extends Form {
  state = {
    data: { title: '', date: new Date(), songCount: 0 },
    errors: {},
  };

  schema = {
    title: Joi.string().max(25).required().label('Service Title'),
    date: Joi.date().required().label('Date'),
    songCount: Joi.number().min(1).required(),
  };

  componentDidMount() {
    const { title, date, currentService } = this.props;
    const data = { ...this.state.data };

    if (this.props.editServiceId) {
      data.title = title;
      data.date = new Date(date);
      data.songCount = currentService.length; // for validation purpose
      this.setState({ data });
    } else {
      data.songCount = currentService.length;
      this.setState({ data });
    }
  }

  handleDateChange = (date) => {
    const data = { ...this.state.data };
    data.date = date;
    this.setState({ data });
  };

  handleDeleteSong = (song) => {
    // remove 1 from songCount
    const data = { ...this.state.data };
    data.songCount--;
    this.setState({ data });
    // pass control
    this.props.onDeleteFromService(song);
  };

  renderSongCount = (songCount) => {
    if (songCount === 0) return null;
    let classes = 'service-form__song-count';
    return (
      <>
        <span className={classes}>
          <span>{songCount}</span>
        </span>
        <br />
      </>
    );
  };

  renderCurrentSongs = () => {
    const { currentService, onSortInService, onDragSortInService } = this.props;

    return currentService.length !== 0 ? (
      <ServiceSongs
        currentService={currentService}
        onDeleteFromService={this.handleDeleteSong}
        onSortInService={onSortInService}
        onDragSortInService={onDragSortInService}
      />
    ) : (
      <NoContent message="No songs have been added." />
    );
  };

  renderBtnText = () => {
    return this.props.editServiceId ? 'Save Changes' : 'Create Service';
  };

  doSubmit = () => {
    const { title, date } = this.state.data;
    this.props.onCreateService(title, date);
  };

  render() {
    const { songCount } = this.state.data;
    const { savingService, onToggleCreateService } = this.props;

    return (
      <>
        <TopMenu
          menu={[
            {
              title: 'Add Songs',
              icon: <AddCircleIcon />,
              handler: onToggleCreateService,
            },
          ]}
        />
        <div className="container">
          <div className="service-form">
            <Title name="Create Service" icon={<CreateServiceIcon />} />
            <form onSubmit={this.handleSubmit}>
              <div className="form-row">
                <div className="col-lg-7">
                  <h2 className="service-form__heading">Songs {this.renderSongCount(songCount)}</h2>
                  {this.renderCurrentSongs()}
                </div>
                <div className="col-lg-5">
                  <div className="service-form__right">
                    <h2 className="service-form__heading">Info</h2>
                    <Calendar onChange={this.handleDateChange} value={this.state.data.date} minDate={new Date()} />
                    {this.renderInput('title', 'Service Title', 'text')}
                    {this.renderButton(this.renderBtnText(), savingService)}
                    {this.renderInput('date', 'Date', 'text')}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default ServiceForm;
