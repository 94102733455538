import { useRef } from 'react';
import Icon from './Icon';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import { ReactComponent as CloseIcon } from '../../icons/xmark-circle.svg';

import './SearchBox.scss';

const SearchBox = ({ value, label, onChange, onClearInput }) => {
  const inputRef = useRef();

  function renderIcon() {
    if (value.length > 0)
      return (
        <span onClick={clearInput} className="clickable search-clear-btn">
          <Icon icon={<CloseIcon />} />
        </span>
      );
    else
      return (
        <span>
          <Icon icon={<SearchIcon />} />
        </span>
      );
  }

  function clearInput() {
    onClearInput();
    inputRef.current.focus();
  }

  return (
    <div className="search-box-container" id="search-box-scroll-id">
      <input
        ref={inputRef}
        type="text"
        name="query"
        value={value}
        className="form-control search-box"
        placeholder={label}
        onChange={(e) => onChange(e.currentTarget.value)}
      />
      {renderIcon()}
    </div>
  );
};

export default SearchBox;
